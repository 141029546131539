// Variables
//
// Don't update any bootstrap files. Add your overwriting and themplate variables here.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-cssgrid: false;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

// Prefix for :root CSS variables
//
$variable-prefix: jfo-;

// Z-Index Master List
//
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-overlay: 1030;
$zindex-image-item: 1040;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
$zindex-widget: 1890;
$zindex-widget-overlay: 1891;
$zindex-modal-backdrop: 1940;
$zindex-modal: 1950;
$zindex-mobilenav: 2009;
$zindex-navbar: 2010;

// Images
//
$base-img-path: "../img/";

// Transitions
//
$transition-base: all .3s .175s cubic-bezier(.25, .8, .25, 1);
$transfrom-transition: transform .5s cubic-bezier(.25, .8, .25, 1), opacity .5s cubic-bezier(.25, .8, .25, 1);
$transition-fade: opacity .15s linear;
$transition-collapse: height .35s ease;
$transition-collapse-width: width .35s ease;

// Fonts
//
$base-font-path: "../fonts/";
$generic-fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Colors
//
$primary: #002043;
$secondary: #a3acb1;
$dark: #707070;
$light: #f8f9fa;
$white: #ffffff;
$black: #000000;
$success: #28a745;
$warning: #a79a28;
$danger: #a72833;

// Component colors
$body-color: $black;
$lead-color: $body-color;
$border-color: #f6f6f6;
$headings-color: $primary;

// Theme colors
$theme-colors: ();
$theme-colors: map-merge((
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
	"white": $white,
	"black": $black,
), $theme-colors);

// Components
//
// Define common padding and border radius sizes and more.
$border-width: 1px;
$border-widths: (1: 1px, 2: 2px, 3: 3px, 4: 4px, 5: 5px);
$border-radius: .25rem;
$border-radius-sm: .2rem;
$border-radius-lg: .3rem;
$border-radius-pill: 50rem;

$box-shadow: 0 .5rem 1rem rgba($black, .15);
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);
$box-shadow-inset: inset 0 1px 2px rgba($black, .075);

$component-active-color: $white;
$component-active-bg: $primary;

$aspect-ratios: ("1x1": 100%,
	"4x3": calc(3 / 4 * 100%),
	"16x9": calc(9 / 16 * 100%),
	"21x9": calc(9 / 21 * 100%));

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: var(--#{$variable-prefix}font-sans-serif);
$font-family-code: var(--#{$variable-prefix}font-monospace);
$headings-font-family: var(--#{$variable-prefix}font-sans-serif);

$font-size-root: null;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-light;
$headings-font-weight: bold;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (1: $h1-font-size,
	2: $h2-font-size,
	3: $h3-font-size,
	4: $h4-font-size,
	5: $h5-font-size,
	6: $h6-font-size);

// Spacing
//
$spacer: 1rem;
$spacers: (
	0: 0,
	1: ($spacer * 1),
	2: ($spacer * 1.5),
	3: ($spacer * 2),
	4: ($spacer * 3),
	5: ($spacer * 4.5),
	6: ($spacer * 5.8),
	7: ($spacer * 7),
	8: ($spacer * 8),
	9: ($spacer * 9),
	10: ($spacer * 10),
);

// Navbars
//
$navbar-padding-y: 0;
$navbar-brand-padding-y: 0;
$navbar-light-color: $primary;
$navbar-light-hover-color: $dark;
$navbar-light-active-color: $dark;
$navbar-light-disabled-color: rgba($black, .3);

// Links
//
$link-decoration: none;

// Lead texts
//
$lead-font-weight: 400;

// Container
//
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1190px,
	xxl: 1320px
);

// Accordion
//
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-padding-y: 0;
$accordion-padding-x: 0;
$accordion-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $primary;
$accordion-button-color: $primary;
$accordion-icon-color: $primary;
$accordion-icon-active-color: $accordion-icon-color;
$accordion-button-focus-box-shadow: none;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$accordion-icon-color}' class='bi bi-caret-right-fill' viewBox='0 0 16 16'><path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$accordion-icon-active-color}' class='bi bi-caret-down-fill' viewBox='0 0 16 16'><path d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/></svg>");

// Bootstrap Icons
$bootstrap-icons-font-src: url("../fonts/bootstrap-icons.woff2?524846017b983fc8ded9325d94ed40f3") format("woff2"), url("../fonts/bootstrap-icons.woff?524846017b983fc8ded9325d94ed40f3") format("woff");

// Buttons
$input-btn-padding-y-lg: 1.25rem;
$input-btn-padding-x-lg: 2.1rem;
