/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

:root {
	--robotoBlack: "Roboto-Black";
	--robotoBlackItalic: "Roboto-BlackItalic";
	--robotoBold: "Roboto-Bold";
	--robotoBoldItalic: "Roboto-BoldItalic";
	--robotoItalic: "Roboto-Italic";
	--robotoLight: "Roboto-Light";
	--robotoLightItalic: "Roboto-LightItalic";
	--robotoMedium: "Roboto-Medium";
	--robotoMediumItalic: "Roboto-MediumItalic";
	--robotoRegular: "Roboto-Regular";
	--robotoThin: "Roboto-Thin";
	--robotoThinItalic: "Roboto-ThinItalic";
}

@font-face {
	font-family: "Roboto-Black" ;
	src: url("../fonts/Roboto-Black.eot") format("embedded-opentype"),
	url("../fonts/Roboto-Black.otf") format("opentype"),
	url("../fonts/Roboto-Black.svg") format("svg"),
	url("../fonts/Roboto-Black.tff") format("truetype"),
	url("../fonts/Roboto-Black.woff") format("woff"),
	url("../fonts/Roboto-Black.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-BlackItalic" ;
	src: url("../fonts/Roboto-BlackItalic.eot") format("embedded-opentype"),
	url("../fonts/Roboto-BlackItalic.otf") format("opentype"),
	url("../fonts/Roboto-BlackItalic.svg") format("svg"),
	url("../fonts/Roboto-BlackItalic.tff") format("truetype"),
	url("../fonts/Roboto-BlackItalic.woff") format("woff"),
	url("../fonts/Roboto-BlackItalic.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-Bold" ;
	src: url("../fonts/Roboto-Bold.eot") format("embedded-opentype"),
	url("../fonts/Roboto-Bold.otf") format("opentype"),
	url("../fonts/Roboto-Bold.svg") format("svg"),
	url("../fonts/Roboto-Bold.tff") format("truetype"),
	url("../fonts/Roboto-Bold.woff") format("woff"),
	url("../fonts/Roboto-Bold.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-BoldItalic" ;
	src: url("../fonts/Roboto-BlackItalic.eot") format("embedded-opentype"),
	url("../fonts/Roboto-BlackItalic.otf") format("opentype"),
	url("../fonts/Roboto-BlackItalic.svg") format("svg"),
	url("../fonts/Roboto-BlackItalic.tff") format("truetype"),
	url("../fonts/Roboto-BlackItalic.woff") format("woff"),
	url("../fonts/Roboto-BlackItalic.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-Italic" ;
	src: url("../fonts/Roboto-Italic.eot") format("embedded-opentype"),
	url("../fonts/Roboto-Italic.otf") format("opentype"),
	url("../fonts/Roboto-Italic.svg") format("svg"),
	url("../fonts/Roboto-Italic.tff") format("truetype"),
	url("../fonts/Roboto-Italic.woff") format("woff"),
	url("../fonts/Roboto-Italic.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-Light" ;
	src: url("../fonts/Roboto-Light.eot") format("embedded-opentype"),
	url("../fonts/Roboto-Light.otf") format("opentype"),
	url("../fonts/Roboto-Light.svg") format("svg"),
	url("../fonts/Roboto-Light.tff") format("truetype"),
	url("../fonts/Roboto-Light.woff") format("woff"),
	url("../fonts/Roboto-Light.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-LightItalic" ;
	src: url("../fonts/Roboto-LightItalic.eot") format("embedded-opentype"),
	url("../fonts/Roboto-LightItalic.otf") format("opentype"),
	url("../fonts/Roboto-LightItalic.svg") format("svg"),
	url("../fonts/Roboto-LightItalic.tff") format("truetype"),
	url("../fonts/Roboto-LightItalic.woff") format("woff"),
	url("../fonts/Roboto-LightItalic.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-Medium" ;
	src: url("../fonts/Roboto-Medium.eot") format("embedded-opentype"),
	url("../fonts/Roboto-Medium.otf") format("opentype"),
	url("../fonts/Roboto-Medium.svg") format("svg"),
	url("../fonts/Roboto-Medium.tff") format("truetype"),
	url("../fonts/Roboto-Medium.woff") format("woff"),
	url("../fonts/Roboto-Medium.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-MediumItalic" ;
	src: url("../fonts/Roboto-MediumItalic.eot") format("embedded-opentype"),
	url("../fonts/Roboto-MediumItalic.otf") format("opentype"),
	url("../fonts/Roboto-MediumItalic.svg") format("svg"),
	url("../fonts/Roboto-MediumItalic.tff") format("truetype"),
	url("../fonts/Roboto-MediumItalic.woff") format("woff"),
	url("../fonts/Roboto-MediumItalic.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-Regular" ;
	src: url("../fonts/Roboto-Regular.eot") format("embedded-opentype"),
	url("../fonts/Roboto-Regular.otf") format("opentype"),
	url("../fonts/Roboto-Regular.svg") format("svg"),
	url("../fonts/Roboto-Regular.tff") format("truetype"),
	url("../fonts/Roboto-Regular.woff") format("woff"),
	url("../fonts/Roboto-Regular.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-Thin" ;
	src: url("../fonts/Roboto-Thin.eot") format("embedded-opentype"),
	url("../fonts/Roboto-Thin.otf") format("opentype"),
	url("../fonts/Roboto-Thin.svg") format("svg"),
	url("../fonts/Roboto-Thin.tff") format("truetype"),
	url("../fonts/Roboto-Thin.woff") format("woff"),
	url("../fonts/Roboto-Thin.woff2") format("woff2");
}

@font-face {
	font-family: "Roboto-ThinItalic" ;
	src: url("../fonts/Roboto-ThinItalic.eot") format("embedded-opentype"),
	url("../fonts/Roboto-ThinItalic.otf") format("opentype"),
	url("../fonts/Roboto-ThinItalic.svg") format("svg"),
	url("../fonts/Roboto-ThinItalic.tff") format("truetype"),
	url("../fonts/Roboto-ThinItalic.woff") format("woff"),
	url("../fonts/Roboto-ThinItalic.woff2") format("woff2");
}

/* FONTS-CLASSES */
.roboto-black {
	font-family: var(--robotoBlack);
}

.roboto-black-italic {
	font-family: var(--robotoBlackItalic);
}

.roboto-bold {
	font-family: var(--robotoBold);
}

.roboto-bold-italic {
	font-family: var(--robotoBoldItalic);
}

.roboto-italic {
	font-family: var(--robotoItalic);
}

.roboto-light {
	font-family: var(--robotoLight);
}

.roboto-light-italic {
	font-family: var(--robotoLightItalic);
}

.roboto-medium {
	font-family: var(--robotoMedium);
}

.roboto-medium-italic {
	font-family: var(--robotoMediumItalic);
}

.roboto-regular {
	font-family: var(--robotoRegular);
}

.roboto-thin {
	font-family: var(--robotoThin);
}

.roboto-thin-italic {
	font-family: var(--robotoThinItalic);
}
