header#masthead {
	transition: $transition-base;
	
	// Scroll Helper Classes
	.switch-header & {
		transition: $transition-base;
		background: $primary;
		box-shadow: $box-shadow-sm;
		
		.page-logo {
			transition: $transition-base;
			height: 60px;
			width: auto;
		}
	}
	
	.page-logo {
		transition: $transition-base;
		height: 120px;
		width: auto;
	}
	
	.menu-btn {
		border: 0;
		background: 0;
		
		i {
			font-size: 200%;
			line-height: 1;
			color: $white;
		}
	}
	
	.offcanvas-body {
		font-weight: $font-weight-bold;
		font-size: 1.25rem;
	}
	
	nav {
		> ul {
			text-align: right;
			text-transform: uppercase;
			
			> li {
				margin-top: 3rem;
				margin-bottom: 3rem;
			}
		}
		
		a {
			font-weight: $font-weight-bold;
			color: $white;
			
			&:hover {
				color: $white;
			}
		}
		
		> ul > li > ul > li {
			font-size: 1rem;
		}
		
		> ul > li > a,
		> ul > li > ul > li {
			padding-right: 1.5rem;
		}
		
		> ul > li > ul > li > a {
			font-size: 1rem;
			text-transform: none;
		}
	}
}

footer#colophon {
	ul a {
		color: $white;
		
		&:hover {
			color: $white;
		}
	}
}
