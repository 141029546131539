h3 {
	font-size: 2.5rem;
	text-transform: uppercase;
}

i {
	font-size: 1rem;
	margin-right: 0.5rem;
}

.btn {
	font-weight: $font-weight-light;
}

.btn-close {
	opacity: 1;
}

ul {
	list-style: none;
	padding-inline-start: 0;
}

hr {
	width: 5rem;
	opacity: 1;
	color: $secondary;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}
