@import "variables";
@import "fonts";
@import "bootstrap";

// Theming
@import "reset";
@import "partials";

// Bootstrap icons
@import "~bootstrap-icons/font/bootstrap-icons";

#intro {
	padding-top: 120px;
}

.section-heading {
	display: inline-block;
	font-family: $font-weight-bold;
	color: $black;
	@include font-size(1.25rem);
	text-transform: uppercase;
	margin-right: 1rem;
}

.heading-subline {
	font-family: var(--robotoThin);
	margin-left: 1rem;
}

.vertical-strokes {
	display: block;
	color: $primary;
	line-height: 2.5rem;
	font-size: 3rem;
	margin-bottom: 1rem;
}

.counter-block {
	margin-top: 2rem;
	margin-bottom: 2rem;
	color: $light;
}

.counter-block > span {
	display: block;
}

.counter-container {
	.col-6:last-of-type {
		@include media-breakpoint-down(md) {
			border-left: 0 !important;
		}
	}
}

.accordion-button {
	margin-bottom: .5rem;
	
	&:after {
		order: -1;
		margin-left: 0;
		margin-right: 0.5em;
	}
}

.bg-full {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

ul.bio {
	li {
		position: relative;
		margin-left: 1.5rem;
		margin-bottom: .75rem;
		
		&:before {
			position: absolute;
			left: -1.5rem;
			top: 2px;
			content:  map-get($bootstrap-icons-map, "caret-right-fill");
			display: inline-block;
			font-family: "bootstrap-icons" !important;
			font-style: normal;
			color: $primary;
			
		}
	}
}
